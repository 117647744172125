<template>
  <div
    id="home"
    class="home"
  >
    <div
      class="header shadow"
      :class="{ 'expanded': sidebarExpanded }"
    >
      <div></div>
      <a
        class="icon d-lg-none mr-auto"
        @click.prevent.stop="setSidebar(!sidebar)"
      >
        <fa-icon
          icon="bars"
          size="lg"
        />
      </a>
      <div
        class="d-flex align-items-center mr-2"
      >
        <b-dropdown
          menu-class="shadow"
          variant="outlane-primary"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <div class="profile d-flex align-items-center">
              <div>
                <small>Shortcut</small>
              </div>
              <div>
                <fa-icon
                  class="ml-2"
                  icon="chevron-down"
                  size="sm"
                />
              </div>
            </div>
          </template>
          <b-dropdown-item>
            <b-link @click="goToNewProject(item)">
              Add New Project
            </b-link>
          </b-dropdown-item>
        </b-dropdown>
        <fa-icon
          class="ml-3"
          :icon="['far', 'bell']"
          size="lg"
        />
        <fa-icon
          class="ml-3"
          :icon="['far', 'calendar-alt']"
          fixed-width
          size="lg"
        />
        <b-dropdown
          menu-class="shadow"
          variant="outlane-primary"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <div class="profile d-flex align-items-center">
              <b-avatar
                button
                variant="secondary"
                :text="profile.fullname.charAt(0)"
                class="align-baseline mr-3 text-white"
              />
              <div>
                <span><small>Hi, {{ profile.fullname }}</small></span>
                <br>
                <span class="text-muted">
                  <small>{{ profile.email }}</small>
                </span>
              </div>
            </div>
          </template>
          <b-dropdown-item @click="goToProfile">
            Profile
          </b-dropdown-item>
          <b-dropdown-item @click="logout">
            Logout
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <home-sidebar
      :active="sidebar"
      :expanded="sidebarExpanded"
      :locked="sidebarLocked"
      @reset="setSidebar"
      @expand="expandSidebar"
      @lock="lockSidebar"
    />
    <div
      class="overlay"
      :class="{ 'shown': sidebar }"
    />
    <main
      id="main"
      :class="{ 'expanded': !sidebarExpanded }"
    >
      <div>
        <div class="w-100 mb-4 ml-lg-2">
          <h3 class="mb-0 font-weight-bold">
            {{ title }}
          </h3>
        </div>
        <div>
          <router-view />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import api from '@/api'
import HomeSidebar from '../components/HomeSidebar.vue'
import Vue from 'vue'

export default {
  components: {
    HomeSidebar
  },

  data: () => ({
    sidebar: false,
    sidebarExpanded: false,
    sidebarLocked: false,
    profile: JSON.parse(localStorage.getItem('me'))
  }),

  computed: {
    title() {
      return this.$route.name
    }
  },

  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title
      }
    }
  },

  methods: {
    setSidebar(value) {
      this.sidebar = value
    },
    expandSidebar(value) {
      this.sidebarExpanded = value
    },
    lockSidebar() {
      this.sidebarLocked = !this.sidebarLocked
    },
    goToProfile() {
      this.$router.push('/view-profile')
    },
    goToNewProject() {
      this.$router.push('/new-project')
      localStorage.removeItem('projectId')
      localStorage.removeItem('projectCode')
      localStorage.removeItem('clientId')
      localStorage.removeItem('projectResource')
      localStorage.removeItem('projectName')
      localStorage.removeItem('projectStatus')
      localStorage.removeItem('amount')
      localStorage.removeItem('step')
      localStorage.setItem('step', 1)
    },
    async logout() {
      try {
        await api.auth.logout()
      } catch (response) {
        this.$nextTick(() => {
          this.$bvToast.toast(`${response.status} ${response.data.error.message}`, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
      localStorage.clear()
      Vue.prototype.stopSignalR()
      this.$router.push({ path: '/login', replace: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin: 10px 20px 0 20px;
  border-radius: 12px;
  position: static;
  height: 56px;
  transition: 0.3s;

  &.expanded {
    margin-left: 285px;
  }

  @media screen and (min-width: 992px) {
    margin-left: 110px;
  }
}

@media (min-width: 600px) {
  .header {
    min-height: 64px;
  }
}

@media (min-width: 0px) and (orientation: landscape) {
  .header {
    min-height: 48px;
  }
}

.home {
  background: #F5F6F9;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  overflow: hidden;
}

.overlay {
  background: #000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.3s;
  visibility: hidden;
  z-index: 102;

  &.shown {
    opacity: 0.9;
    visibility: visible;
  }
}

main {
  background-color: #F5F6F9;
  margin-top: 20px;
  margin-left: 12px;
  margin-bottom: 10px;
  padding: 0px 20px 0px 20px;
  transition: 0.3s;
  height: calc(100% - 95px);
  overflow: auto;

  @media screen and (min-width: 992px) {
    margin-left: 260px;

    &.expanded {
      margin-left: 90px;
    }
  }
}

.profile {
  color: #000;
  font-size: 1rem;
  padding: 5px;
}

.role {
  font-size: 1.3rem;
}

.icon-user {
  font-size: 30px;
}

.icon {
  color: inherit;
  padding: 10px 15px;
}
</style>
