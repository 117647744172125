const projectMenu = [
  {
    path: '/manage-client',
    text: 'Manage Client',
    icon: 'user-friends'
  },
  {
    path: '/project-list',
    text: 'Project',
    icon: 'layer-group'
  },
  {
    path: '/timeline-by-project',
    text: 'Timeline by Project',
    icon: 'file-alt'
  },
  {
    path: '/timeline-by-employee',
    text: 'Timeline by Employee',
    icon: 'file-alt'
  }
]

const financeMenu = [
  {
    path: '/invoice-list',
    text: 'Invoice',
    icon: 'file-alt'
  },
  {
    path: '/manage-expenses',
    text: 'Manage Expenses',
    icon: 'file-alt'
  }
]

const settingsMenu = [
  {
    path: '/manage-type',
    text: 'Manage Type',
    icon: 'file-alt'
  },
  {
    path: '/manage-activity',
    text: 'Manage Activity',
    icon: 'file-alt'
  },
  {
    path: '/manage-development-role',
    text: 'Manage Development Role',
    icon: 'file-alt'
  },
  {
    path: '/manage-developer',
    text: 'Manage Developer',
    icon: 'file-alt'
  },
  {
    path: '/manage-role',
    text: 'Manage Role',
    icon: 'file-alt'
  },
  {
    path: '/manage-user',
    text: 'User Management',
    icon: 'user-friends'
  }
]

const menusHome = [
  {
    path: '/dashboard',
    text: 'Home',
    icon: 'home',
    source: require('@/assets/icon-menu-home.png')
  }
]

const menus = [
  {
    path: '/summary-profit',
    text: 'Summary Profit',
    icon: 'file-alt',
    source: require('@/assets/icon-menu-summary-profit.png')
  },
  {
    path: '/budgeting',
    text: 'Budgeting',
    icon: 'file-alt',
    source: require('@/assets/icon-menu-budgeting.png')
  },
  {
    path: '/manage-employees',
    text: 'Employee',
    icon: 'user-friends',
    source: require('@/assets/icon-menu-employee.png')
  }
]

export { projectMenu, financeMenu, settingsMenu, menusHome, menus }
