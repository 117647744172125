<template>
  <aside
    :class="{
      'active': active,
      'expanded': expanded
    }"
    class="shadow"
    v-click-outside="reset"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <Transition>
      <div v-if="expanded" class="ml-3">
        <fa-icon
          class="mr-4"
          icon="th-large"
          fixed-width
          size="lg"
          @click="$emit('lock')"
        />
        <img
          class="ml-2"
          id="imgLogo"
          src="@/assets/sidebar-logo.png"
          alt="logo"
          width="45%"
        >
      </div>
    </Transition>
    <div class="menus">
      <div class="p-1 mb-2">
        <router-link
          class="link text-nowrap"
          v-for="(menu, m) of menusHome"
          :key="1-m"
          :to="menu.path"
          :exact="menu.exact"
          @click.stop.native="reset"
        >
          <img
            class="ml-1"
            id="imgHome"
            :src="menu.source"
            alt="logo"
            width="15"
          >
          <span class="ml-3">
            {{ menu.text }}
          </span>
        </router-link>
        <div
          class="link text-nowrap"
          :class="expanded ? 'd-flex justify-content-center' : ''"
          v-b-toggle.collapse-1
        >
          <img
            class="ml-1"
            :class="expanded ? 'mt-1' : ''"
            id="imgHome"
            src="@/assets/icon-menu-project.png"
            alt="logo"
            width="15"
            height="15"
          >
          <span class="ml-3">
            Project
          </span>
          <fa-icon
            v-if="expanded"
            class="ml-auto mt-1"
            :icon="collapseOneVisible ? 'caret-down' : 'caret-up'"
          />
        </div>
        <b-collapse
          id="collapse-1"
          class="mt-2 text-nowrap"
          v-model="collapseOneVisible"
        >
          <b-card>
            <router-link
              class="sub-link"
              v-for="(menu, m) of projectMenu"
              :key="2-m"
              :to="menu.path"
              :exact="menu.exact"
            >
              &bull; {{ menu.text }}
            </router-link>
          </b-card>
        </b-collapse>
        <div
          class="link text-nowrap"
          :class="expanded ? 'd-flex justify-content-center' : ''"
          v-b-toggle.collapse-2
        >
          <img
            class="ml-1"
            :class="expanded? 'margin-icon' : ''"
            id="imgHome"
            src="@/assets/icon-menu-finance.png"
            alt="logo"
            width="15"
            height="12"
          >
          <span class="ml-3">
            Finance
          </span>
          <fa-icon
            v-if="expanded"
            class="ml-auto mt-1"
            :icon="collapseTwoVisible ? 'caret-down' : 'caret-up'"
          />
        </div>
        <b-collapse
          id="collapse-2"
          class="mt-2 text-nowrap"
          v-model="collapseTwoVisible"
        >
          <b-card>
            <router-link
              class="sub-link"
              v-for="(menu, m) of financeMenu"
              :key="3-m"
              :to="menu.path"
              :exact="menu.exact"
            >
              &bull; {{ menu.text }}
            </router-link>
          </b-card>
        </b-collapse>
        <router-link
          class="link text-nowrap"
          v-for="(menu, m) of menus"
          :key="4-m"
          :to="menu.path"
          :exact="menu.exact"
          @click.stop.native="reset"
          @click="selected = null"
        >
          <img
            class="ml-1"
            id="imgHome"
            :src="menu.source"
            alt="logo"
            width="15"
          >
          <span class="ml-3">
            {{ menu.text }}
          </span>
        </router-link>
        <div
          class="link text-nowrap"
          :class="expanded ? 'd-flex justify-content-center' : ''"
          v-b-toggle.collapse-3
        >
          <img
            class="ml-1"
            :class="expanded ? 'mt-1' : ''"
            id="imgHome"
            src="@/assets/icon-menu-settings.png"
            alt="logo"
            width="15"
            height="15"
          >
          <span class="ml-3">
            Settings
          </span>
          <fa-icon
            v-if="expanded"
            class="ml-auto mt-1"
            :icon="collapseThreeVisible ? 'caret-down' : 'caret-up'"
          />
        </div>
        <b-collapse
          id="collapse-3"
          class="mt-2 text-nowrap"
          v-model="collapseThreeVisible"
        >
          <b-card>
            <router-link
              class="sub-link"
              v-for="(menu, m) of settingsMenu"
              :key="5-m"
              :to="menu.path"
              :exact="menu.exact"
            >
              &bull; {{ menu.text }}
            </router-link>
          </b-card>
        </b-collapse>
      </div>
    </div>
  </aside>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { projectMenu, financeMenu, settingsMenu, menus, menusHome } from '../fixtures/menuSidebar'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isLock: null,
    collapseOneVisible: false,
    collapseOneVisibleBefore: false,
    collapseTwoVisible: false,
    collapseTwoVisibleBefore: false,
    collapseThreeVisible: false,
    collapseThreeVisibleBefore: false
  }),

  computed: {
    menus() {
      return menus
    },
    projectMenu() {
      return projectMenu
    },
    financeMenu() {
      return financeMenu
    },
    settingsMenu() {
      return settingsMenu
    },
    menusHome() {
      return menusHome
    }
  },

  methods: {
    reset() {
      if (this.active) {
        this.$emit('reset', false)
      }
    },
    onMouseEnter() {
      this.$emit('expand', true)
      if (this.collapseOneVisibleBefore) {
        this.collapseOneVisible = true
      }
      if (this.collapseTwoVisibleBefore) {
        this.collapseTwoVisible = true
      }
      if (this.collapseThreeVisibleBefore) {
        this.collapseThreeVisible = true
      }
    },
    onMouseLeave() {
      if (!this.locked) {
        this.$emit('expand', false)
        this.collapseOneVisibleBefore = this.collapseOneVisible
        this.collapseOneVisible = false
        this.collapseTwoVisibleBefore = this.collapseTwoVisible
        this.collapseTwoVisible = false
        this.collapseThreeVisibleBefore = this.collapseThreeVisible
        this.collapseThreeVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.icon{
  margin-left: 10px;
}

.margin-icon {
  margin-top: 6px;
}
aside {
  background: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  padding: 16px;
  border-radius: 12px;
  top: 0;
  transform: translateX(-100%);
  transition: 0.3s;
  width: 80px;
  z-index: 103;
  margin: 10px 0px 10px 0px;

  &.active {
    transform: translateX(0);
  }

  &.expanded {
    width: 260px;
  }

  @media screen and (min-width: 992px) {
    transform: translateX(0);
    margin: 10px 10px 10px 10px;
  }
}

.menus {
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;

  .expanded & {
    overflow-y: auto;
  }
}

.link {
  color: #000;
  display: block;
  font-size: 14px;
  padding: 11px 0px 11px 9px;
  position: relative;
  text-decoration: none;

  span {
    display: none;

    .expanded & {
      display: inline-block;
    }
  }
}

.sub-link {
  color: inherit;
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 10px 15px;
  position: relative;
  text-decoration: none;
}

.router-link-exact-active {
   background-color: #F9BF59;
   cursor: pointer;
   border-radius: .25rem;
 }

.btn-primary:hover {
  background-color: #A2A6F6;
  border-color: #A2A6F6;
}

.btn-primary:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: none;
}

.card {
  background: var(--primary);
  border-radius: 10px;
}

.card-body {
  padding: 10px 10px 0 10px;
}

:deep() .collapse.show {
  height: 0;
  opacity: 0;
  transition: 0.3s;
  visibility: hidden;

  .expanded & {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}
</style>
